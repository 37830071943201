import React, { useCallback, useEffect, useState } from 'react';
import { createContext } from 'react';

/**
 * Initializes a context for keeping track of 'dirty' forms.
 * Provider will be handled on a top level, by wrapping the
 * top level component with FormContextWrapper.
 * value is supposed to be consumed by the `components/common/form/form.js`
 * value is supposed to be a function that enables form to register itself
 * as dirty/not dirty
 */
export const FormContext = createContext();

/**
 * This component is used as a part of the change in the
 * `topLevel` function, see `hoc.js`.
 * we use it to keep track of dirty forms, and prevent the
 * page change if needed
 */
export const FormContextWrapper = ({ children }) => {
  const [dirtyForms, setDirtyForms] = useState({});
  const isDirty = Object.values(dirtyForms).some(Boolean);

  // a function exposed through FormContext.
  // we wrapp it in the `useCallback` to ensure
  // reffernce stability, thus preventing unecessery
  // re-renders
  const setDirty = useCallback(
    ({ id, isDirty }) => {
      setDirtyForms((oldState) => {
        if (id in oldState && !isDirty) {
          delete oldState[id];
          return { ...oldState };
        } else if (!oldState[id] && isDirty) {
          return { ...oldState, [id]: isDirty };
        }
        return oldState;
      });
    },
    [setDirtyForms]
  );

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isDirty]);

  return (
    <FormContext.Provider value={setDirty}>{children}</FormContext.Provider>
  );
};
