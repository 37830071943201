import React from 'react';
import PropTypes from 'prop-types';

const isCurrentLocale = (locale, currentLocale) => {
  return locale === currentLocale;
};

const LocaleSpan = ({ locale }) => {
  return (
    <span className="locale" role="status">
      {locale}
    </span>
  );
};

const LocaleLink = ({ locale }) => {
  return (
    <a href={`/locale/${locale}`} className="locale">
      {locale}
    </a>
  );
};

const LocaleSelector = ({ availableLocales, currentLocale }) => {
  return (
    <>
      {availableLocales.map((locale) => (
        <React.Fragment key={locale}>
          &nbsp;
          {isCurrentLocale(locale, currentLocale) ? (
            <LocaleSpan locale={locale} />
          ) : (
            <LocaleLink locale={locale} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

LocaleSelector.propTypes = {
  availableLocales: PropTypes.arrayOf(PropTypes.string),
  currentLocale: PropTypes.string,
};

export default LocaleSelector;
