import { createContext } from 'react';

export const StateContext = createContext(null);

export const DispatchContext = createContext(null);

/**
 * StoreProvider component provides state and dispatch contexts to its children.
 * It is designed to work with the useReducer hook, enabling state management
 * through a centralized store.
 *
 * state: from the useReducer hook.
 * dispatch: The dispatch function from useReducer.
 * children: The child components that will have access to the context.
 */
export const StoreProvider = ({ state, dispatch, children }) => (
  <StateContext.Provider value={state}>
    <DispatchContext.Provider value={dispatch}>
      {children}
    </DispatchContext.Provider>
  </StateContext.Provider>
);
