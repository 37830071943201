import React from 'react';

// https://tobiasahlin.com/spinkit/
const Spinner = () => (
  <div className="spinner" role="spinbutton">
    <div className="pull-right sk-bounce">
      <div className="sk-bounce-dot"></div>
      <div className="sk-bounce-dot"></div>
    </div>
  </div>
);

export default Spinner;
