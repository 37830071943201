/**
 * A small lib for managing user tracking consent in a GDPR-compliant way,
 * so that tracking code is only executed once user consent has been given.
 * See also the CookieBanner react component.
 * Adapted from https://github.com/Alex-D/Cookies-EU-banner
 *
 * We use localStorage to store consent but for a little while we used a cookie.
 * Cookies aren't a good solution because of chrome's restrictions on third-party
 * cookies in an iframe. We still check for the cookie so that we don't annoy
 * users who already set it, it should be possible to remove this check after a
 * year or so.
 */
const CookieConsent = {
  /**
   * A list of functions that initialize tracking code when called.
   * Tracking code is kept here to be executed when the user gives consent.
   * If the user has already consented, tracking code will be executed
   * immediately and this list will remain empty.
   */
  trackedSnippets: [],

  // Name of the cookie used to track user consent
  cookieName: 'hasConsent',
  // 12 months in milliseconds. According to GDPR user consent should expire after this period
  cookieTimeout: 31104000000,

  // We make an attempt to hide the consent popup from crawlers,
  // so that it won't appear in search results.
  bots: /bot|crawler|spider|crawling/i,
  // We make an attempt to clear some known, accessible cookies
  // when a user refuses to give consent.
  trackingCookies: /^(_ga|_gid|__hs|__zl|_hj|hubspot|__utm)/,

  /**
   * Any third-party code embedded into the page that tracks individual users should be
   * wrapped using this function. The code will then only be executed on the condition
   * that the user has given their consent to be tracked.
   */
  trackingCode(func) {
    if (this.hasConsent()) return func();

    this.trackedSnippets.push(func);
  },

  // Returns true if a consent banner should be shown to the user.
  showBanner() {
    if (this.isBot() || this.doNotTrack() || this.isConsentSet()) {
      if (!this.hasConsent()) this.clearCookies();

      return false;
    }
    return true;
  },

  // Should be called when the user has consented
  acceptCookies() {
    this.setConsent(true);
    this.trackedSnippets.forEach((fn) => fn());
  },

  // Should be called when the user has withheld consent.
  rejectCookies() {
    this.clearCookies();
    this.setConsent(false);
  },

  // User Agent is a bot
  isBot() {
    return this.bots.test(navigator.userAgent);
  },

  // Browser "Do Not Track" feature is enabled
  doNotTrack() {
    const dnt =
      navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
    return dnt === 'yes' || dnt === 1 || dnt === '1';
  },

  // User has given explicit consent for tracking
  hasConsent() {
    return (
      document.cookie.indexOf(`${this.cookieName}=true`) >= 0 ||
      this.getLocalStorageItem() === 'true'
    );
  },

  // User has already made a choice about consent
  isConsentSet() {
    return (
      document.cookie.indexOf(`${this.cookieName}=`) >= 0 ||
      this.getLocalStorageItem() !== null
    );
  },

  getLocalStorageItem() {
    try {
      return window.localStorage.getItem(this.cookieName);
    } catch (e) {
      return null;
    }
  },

  // Stores whether the user has accepted, or explicitly rejected, tracking cookies.
  // We prefer local storage over cookies, because a significant number of our
  // users are accessing the app through an iframe, where modern browsers
  // disallow third party cookies.
  setConsent(accepted) {
    this.setLocalStorageConsent(accepted) || this.setCookieConsent(accepted);
  },

  setLocalStorageConsent(accepted) {
    try {
      // It turns out, the most effective way to detect local storage
      // is to try writing to it:
      // https://stackoverflow.com/q/11214404
      window.localStorage.setItem(this.cookieName, accepted);

      return true;
    } catch (e) {
      return false;
    }
  },

  setCookieConsent(accepted) {
    const hostname = this.hostname(),
      date = new Date();
    date.setTime(date.getTime + this.cookieTimeout);

    // We don't currently have a fallback if cookies are disabled,
    // so we just hope this is going to work.
    document.cookie = `${
      this.cookieName
    }=${accepted};domain=${hostname};expires=${date.toGMTString()};path=/`;
  },

  clearCookies() {
    const hostname = this.hostname(),
      commonSuffix = `domain=${hostname}; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/`;

    const cookies = document.cookie.split(';').map((s) => s.trim());
    for (const cookie of cookies) {
      const name = cookie.split('=')[0].trim();
      if (!this.trackingCookies.test(name)) continue;

      document.cookie = `${name}=; ${commonSuffix}`;
    }
  },

  hostname: () => document.location.hostname.replace(/^[^.]+\./, ''),
};

export default CookieConsent;
