/* global Rollbar */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { translate } from '../../lib/use_i18n';

/**
 * It is a special component which catches exceptions raised while
 * rendering components below this one. Since it isn't possible
 * to recover from an exception (otherwise, it would've been caught
 * and processed somewhere), it shows a general error.
 *
 * More info about this approach can be found here
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    console.error(error, errorInfo);
    Rollbar.error(error);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          <div className="clearfix"></div>
          <div className="alert alert-danger" role="alert">
            {translate(this.props.intl, 'errors.general')}
          </div>
        </>
      );
    }
    // normally render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ErrorBoundary);
