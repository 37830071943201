/* eslint-disable no-undef */

// Object.assign is used inside ReactOnRails, IE 11 users suffer without this polyfill
import 'core-js/features/object/assign';

// disable React auto-rendering
window.__REACT_ON_RAILS_EVENT_HANDLERS_RAN_ONCE__ = true;

/**
 * ReactOnRails must be included only once, otherwise, it removes registered components
 * from another bundle. More explanation of this problem might be found there
 * https://github.com/shakacode/react_on_rails/issues/991
 */
window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

/**
 * Pages might load different bundles with components, it means all components aren't registered
 * right away, React tries to render components once the loading is stopped (see https://bit.ly/34HWLsn).
 * After disabling auto-rendering above, react components must be rendered manually once the DOM is loaded
 * (all components are registered at that time).
 */
if (!window.reactHandlerAdded) {
  window.reactHandlerAdded = true;

  window.addEventListener(
    'DOMContentLoaded',
    window.ReactOnRails.reactOnRailsPageLoaded
  );
}
