import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import ErrorBoundary from './components/common/error_boundary';
import Axios from './components/common/axios';
import { FormContextWrapper } from './contexts';

/**
 * High order components (https://reactjs.org/docs/higher-order-components.html).
 */

/**
 * Sets settings for Axios (https://github.com/axios/axios).
 */
const withAxios = (WrappedComponent) => {
  const wrapper = (props) => (
    <Axios csrfToken={props.csrfToken}>
      <WrappedComponent {...props} />
    </Axios>
  );

  wrapper.displayName = 'withAxios';
  wrapper.propTypes = {
    csrfToken: PropTypes.string.isRequired,
  };

  return wrapper;
};

/**
 * Initializes the intl provider to be used by children.
 */
const withIntl = (WrappedComponent) => {
  const wrapper = (props) => {
    const locale = props.locale || props.defaultLocale;
    const translations = window.translations || {};

    return (
      <IntlProvider locale={locale} key={locale} messages={translations}>
        <WrappedComponent {...props} />
      </IntlProvider>
    );
  };

  wrapper.displayName = 'withIntl';
  wrapper.propTypes = {
    locale: PropTypes.string,
    defaultLocale: PropTypes.string.isRequired,
  };
  return wrapper;
};

/**
 * Adds a global error catcher (https://reactjs.org/docs/error-boundaries.html).
 */
const withErrorBoundary = (WrappedComponent) => {
  const wrapper = (props) => (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  wrapper.displayName = 'withErrorBoundary';

  return wrapper;
};

const withFormContext = (WrappedComponent) => {
  const wrapper = (props) => (
    <FormContextWrapper>
      <WrappedComponent {...props} />
    </FormContextWrapper>
  );

  wrapper.displayName = 'withFormContext';

  return wrapper;
};
/**
 * Setups a given component for use in Rails views.
 */
const topLevel = (WrappedComponent) => {
  return withIntl(
    withAxios(withErrorBoundary(withFormContext(WrappedComponent)))
  );
};

export { withAxios, withIntl, topLevel };
