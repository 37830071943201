/* global Rollbar */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import useI18n from '../../lib/use_i18n';

import Spinner from './spinner';

const redirectIfNeeded = (resp) => {
  const url = (resp['headers'] || {})['location'];
  if (url) window.location.replace(url);
};

// A wrapper function around the rest call functions(`action`) that allows additional
// flexibility in how we handle rest call errors. Most common unhandled errors
// are 422 outside of the form context (form usually handles the 422 response).
export const errorHandleWrapper = async ({ action, getErrorMessage }) => {
  try {
    return await action();
  } catch (error) {
    const errorMessage = getErrorMessage
      ? getErrorMessage(error)
      : error?.response?.data?.error ||
        `HTTP status: ${error?.response?.status}`;
    Flash.alert(errorMessage);
  }
};

const handleError = (error, generalError) => {
  const resp = error.response;

  // If the request is canceled by user, axios will handle it as an error
  if (error?.code === 'ERR_CANCELED') {
    return Promise.reject(error);
  }

  if (resp) {
    const { status, data } = resp;

    switch (status) {
      case 401:
        redirectIfNeeded(resp);
        break;
      case 409:
        redirectIfNeeded(resp);

        // it is about some conflicts, show it
        Flash.alert(data);
        return Promise.reject(data);
      case 422:
        // it is about validation, allow it to pass
        return Promise.reject(error);
      case 424:
        // an external dependency failed
        return Promise.reject(error);

      // don't report it to Rollbar, it is something on the client side, we can do nothing
      default: {
        Flash.alert(generalError);

        const errorMsg = `HTTP status: ${status}; Body: ${data}`;
        return Promise.reject(errorMsg);
      }
    }
  } else {
    Flash.alert(generalError);
    Rollbar.error(error);

    return Promise.reject(error);
  }
};

/**
 * Sets settings for Axios (https://github.com/axios/axios),
 * handles errors occurred after getting a response from the backend.
 *
 * If recovery from an error isn't possible, it will show a general server error.
 *
 * Also, it shows a spinner when there are active requests.
 */
const Axios = ({ csrfToken, children }) => {
  const { translate } = useI18n('errors');
  const [counter, setCounter] = useState(0);

  const decreaseCounter = () => setCounter(counter - 1);

  /**
   * This hook adds interceptors to Axios. Some pages have a few components which get rendered
   * independently in Rails views, such components render this component too. When an error
   * occurs every instance of this component receives that error. In case of validation errors
   * there is no big harm besides several re-rendering but if it is a general error, users would
   * see the same error several times (3 independent components = 3 errors). So, only first Axios
   * component should add interceptors and never remove them even if the component is removed
   * from DOM.
   */
  useEffect(() => {
    if (window.axiosHandlersAdded) return;

    window.axiosHandlersAdded = true;

    const commonHeaders = axios.defaults.headers.common;

    commonHeaders['X-CSRF-Token'] = csrfToken;
    commonHeaders['X-Requested-With'] = 'XMLHttpRequest';

    axios.interceptors.request.use((config) => {
      setCounter(counter + 1);
      return config;
    });

    /**
     * this interceptor processes responses before any other,
     * more about interceptors is here https://github.com/axios/axios#interceptors
     */
    axios.interceptors.response.use(
      (response) => {
        decreaseCounter();
        return response;
      },
      (error) => {
        decreaseCounter();
        return handleError(error, translate('.server'));
      }
    );
  }, []);

  return (
    <>
      {children}
      {counter > 0 && <Spinner />}
    </>
  );
};

Axios.propTypes = {
  csrfToken: PropTypes.string.isRequired,
};

export default Axios;
